import React from 'react';
import styled from 'styled-components';

import Container from 'components/common/container';
import { Heading1 } from 'components/common/text';

const Wrapper = styled.div({
  padding: '40px 0',
  textAlign: 'center',
});

export default function Custom500() {
  return (
    <Container>
      <Wrapper>
        <Heading1>Unknown Error</Heading1>
        <p>
          Sorry, an unknown error occurred processing your request. Please try
          again later.
        </p>
      </Wrapper>
    </Container>
  );
}

export async function getStaticProps() {
  return {
    props: {},
  };
}
